import style from './Layout.module.css'

function Layout({children}) {
  return (
    <div className={style.wrapper}>
      {/* <Header /> */}
      <main className="flex-grow">{children}</main>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout
