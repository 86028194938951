import Head from 'next/head'
import Router from 'next/router'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-calendar/dist/Calendar.css'

import {MOTTO, PRODUCT_NAME} from '@config/texts'
import Layout from '@organism/Layout'

import '../styles/global.css'

// interface AppProps {
//   Component: React.FC<ServerSideProps>
//   pageProps: ServerSideProps
// }

const getLayout = (Component) => Component.getLayout || Layout

export default function App({Component, pageProps} /*: AppProps()*/) {
  const PageLayout = getLayout(Component)

  return (
    <>
      <Head>
        <title>
          {PRODUCT_NAME} - {MOTTO}
        </title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>

      <PageLayout router={Router} backgroundImage={Component?.backgroundImage}>
        <Component {...pageProps} />
      </PageLayout>

      <ToastContainer className="w-full md:w-96" />
    </>
  )
}
